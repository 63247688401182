import React from "react"

const AllEbooks = (data) => {
    let pageData = data.pageContext.data.edges;

    let pageContent = pageData.map((edge) => {
        return <a key={edge.node.id} style={{display:'block',margin:'20px 0'}} href={'/ebooks/'+edge.node.id}>{ edge.node.title }</a>
    })

    return (
        <div>
            <h2>All Ebooks</h2>
            <div>{pageContent}</div>
        </div>
    )
}

export default AllEbooks
